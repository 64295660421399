@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;300;400&display=swap');

@mixin mobile {
    @media screen and (max-width: 499px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: 750px) {
        @content;
    }
}
@mixin large {
    @media screen and (max-width: 1000px) {
        @content;
    }
}

@mixin laptop {
    @media screen and (max-width: 1200px) {
        @content;
    }
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.5px;
}

.homeText{
    width: 100vw;
    height: 100vh;
    background: linear-gradient(135deg,#A0BFE0 0%,#A0BFE0 50%,#1a3361 50%,#1a3361 100%);
    p{
        text-align: center;
        position: absolute;
        top: 50%;
        color: #5e7db7;
    }

}

.container{
    height: 100vh;
    background: linear-gradient(135deg,#A0BFE0 0%,#A0BFE0 50%,#1a3361 50%,#1a3361 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    
    .wrapper{
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px 100px;
        border-radius: 10px;
        align-items: center;
        filter: drop-shadow(0 0 0.75rem #35548f);
        
        .title{
            font-size: 20px;
            font-weight: 600;
            color: #7895CB;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
        
        form{
            width: 150%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 0px 20px;

            input{
                padding: 15px 15px; 
                border: none;
                border: 1.5px solid #7895CB;
                border-radius: 5px;
                outline: none;
                width: 100%;
                &::placeholder{
                    color: #7895CB;
                };
                

                &:focus{
                    border: 1.5px solid #4265a5;
                    border-radius: 5px;
                }
                
            }

            label{
                display: flex;
                align-items: center;
                gap: 5px;
            }

            button{
                padding: 15px;
                background-color: #2a467b;
                color: #ffffff;
                border: none;
                outline: none;
                cursor: pointer;
                border-radius: 5px;
            }
        }

    }
    .credentials{
        text-align: start;
        color: #8f8787;
        font-size: 13px;
    }
}


.home{
    background-color: #244178;
    height: 100vh;
    display: flex;

    .sidepanel{
        background-color: #244178;
        flex: 1.2;
        color: #e4e9ed;
        border-right: 2px solid #3e70a6;
        @include large{
            flex: 2;
        }
        .navbar{
            background-color: #1a3361;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;

            .title {
                font-size: 16px;
                font-weight: 500;
                text-transform: capitalize;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .user{
                display: flex;
                align-items: center;
                gap: 12px;

                
                button{
                    color: #e4e9ed;
                    background-color: #4e6ca6;
                    padding: 5px 10px;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    border-radius: 3px;
                    @include tablet{
                        position: absolute;
                        left: 10px;
                        bottom: 10px;
                    }
                }
            }
        }

        .searchbar{
            .searchform{
                padding: 10px;

                input{
                    background-color: transparent;
                    border-radius: 5px;
                    border: 1px solid #949491;
                    border-bottom: 2px solid #e4e9ed;
                    outline: none;
                    width: 100%;
                    padding: 10px ;
                    color: #e4e9ed;
                    background-color: #4e6ca6;
                    
                    &::placeholder{
                        font-weight: 500;
                        color: #e4e9ed;
                    }

                    &:focus::placeholder {
                        color: transparent;
                    }
                }
            }
            
            .userchat {
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 10px;
                background-color: #244178;
                cursor: pointer;

                &:hover {
                    background-color: #244178;
                }

                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    object-fit: cover;

                    @include tablet {
                        width: 40px;
                        height: 40px;
                    }
                }

                .userchatinfo {
                    padding-bottom: 10px;
                    width: 80%;
                    
                    @include tablet {
                        width: 75%;
                    }
                    
                    span {
                        font-weight: 500;
                        font-size: 1.1rem;
                        text-transform: capitalize;
                    }

                    p {
                        font-size: 0.8rem;
                        color: lightgray;
                    }
                }
            }
        }

        .userlist{
            height: calc(100% - 141px);
            overflow-y: scroll;
            // padding-bottom: 40px;
            .userchat{
                display: flex;
                align-items: center;
                gap: 10px; 
                padding: 10px;
                cursor: pointer;
                
                &:hover{
                    background-color: #1a3361;
                }
                
                img{
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    object-fit: cover;
                    @include tablet {
                        width: 40px;
                        height: 40px;
                    }
                }
                
                .userchatinfo{
                    padding-bottom: 10px;
                    border-bottom:  1px solid #A0BFE0;
                    width: 80%;
                    @include tablet{
                        width: 75%;
                    }
                    span{
                        font-weight: 500;
                        font-size: 1.1rem;
                        text-transform: capitalize;
                    }
                    p{
                        font-size: 0.8rem;
                        color: lightgray;
                    }
                }
            }
        }    
    }

    .chatarea{
        background-color: #e4e9ed;
        flex: 3.8;
        background-image: url('./assets/chat.png');
        background-repeat: no-repeat;
        background-position: center;

        #blankMessage{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            gap: 15px;
            font-size: 1.5rem;
            color: #5e605e;
            @include tablet{
                font-size: 1rem;
            }
        }

        .chatheader{
            background-color: #1a3361;
            color: #e4e9ed;
            display: flex;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .userinfo{
                display: flex;
                align-items: center;
                gap: 15px;
                font-weight: 500;
                text-transform: capitalize;
                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .chaticons{
                display: flex;
                gap: 30px;
            }


        }
        .messages{
            padding: 15px;
            height: calc(100% - 120px);
            overflow-y: scroll;
            width: 100%;
            background-image: url('./assets/wallpaper.jpg');    
            background-size: contain;

            .message{
                display: flex;
                margin-block: 10px;
                padding-block: 5px;

                .messageInfo{
                    display: flex;
                    flex-direction: column;
                    color: gray;
                    gap: 8px;
                    width: 60%;
                    @include tablet{
                        width: 80%;
                    }
                    
                    .messageContent{
                        display: flex;
                        gap: 10px;
                        img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            object-fit: cover;
                            @include tablet {
                                width: 35px;
                                height: 35px;
                            }
                        }
                        #textMessage{
                            background-color: #ffffff;
                            font-size: 0.9rem;
                            padding: 10px;
                            border-radius: 10px;
                            max-width: max-content;
                            width: 90%;
                        }
                    }

                    .time{
                        font-size: 0.7rem;
                    }
                }


                &.owner{
                    flex-direction: row-reverse;
                    .messageContent{
                        flex-direction: row-reverse;
                        #textMessage{
                            background-color: #5576b4;
                            color: #ffffff;
                        }
                    }
                    .messageInfo{
                        align-items: flex-end;
                    }
                }
            }
        }

        .inputbox{
            background-color: #f2f5f7;
            display: flex;
            justify-content: space-evenly;
            padding: 4.2px;
            gap: 5px;

            .sendOptions{
                display: flex;
                gap: 20px;
                align-items: center;
                justify-content: space-evenly;
                color: #4e6ca6;

                button{
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    color: #244178;
                }
            }
        }
    }
}


// Scroll Bar
/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #7895CB;
}
